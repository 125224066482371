import { Router, RouterConfiguration, activationStrategy } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { autoinject, bindable } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { User } from 'oidc-client';
import { OpenIdConnect, OpenIdConnectRoles } from 'aurelia-open-id-connect';
import { EventAggregator } from 'aurelia-event-aggregator';
import { NavigationInstruction, Next, PipelineStep } from 'aurelia-router';
import { CurrentUser } from 'components/current-user/current-user';
import { Profile } from 'users/profile/profile';

const config = require('./config');
const config2 = require('./config');
class Stats {
    public alerts: number = 0;
    public cases: number = 0;
    public transactions: number = 0;
}

import { ErrorService } from 'error/error-service';
import { DialogService } from 'aurelia-dialog';
import { createAppContextProvider } from 'context/app-context';
import { client } from 'tms-client';
import { Config } from '@hey-api/client-fetch';

function setClientConfig(config: Config) {
    client.setConfig(config);
}

@autoinject
export class App {
    router: Router;
    httpClient: HttpClient;
    user: User;
    openIdConnect: OpenIdConnect;
    stats: Stats = new Stats();
    eventAggregator: EventAggregator;
    currentUser: CurrentUser;
    @bindable showLoader: boolean;

    constructor(
        httpClient: HttpClient,
        openIdConnect: OpenIdConnect,
        eventAggregator: EventAggregator,
        currentUser: CurrentUser,
        errorService: ErrorService,
        router: Router,
        dialogService: DialogService,
    ) {
        this.currentUser = currentUser;
        this.httpClient = httpClient;
        this.eventAggregator = eventAggregator;
        this.openIdConnect = openIdConnect;

        this.openIdConnect.observeUser(async (user: User) => {
            this.user = user;

            if (!user) {
                return;
            }

            Profile.load(user.profile);

            const sharedClientDefaults: Config = {
                baseUrl: config2.apiBase,
                credentials: 'same-origin',
                headers: {
                    Accept: 'application/json',
                    'X-Requested-With': 'TMS Alpha',
                    Authorization: `Bearer ${user.access_token}`,
                },
            };

            setClientConfig(sharedClientDefaults);

            this.httpClient.configure((config) => {
                config
                    .useStandardConfiguration()
                    .withBaseUrl(`${config2.apiBase}/v1/api/`)
                    .withDefaults({
                        credentials: sharedClientDefaults.credentials,
                        headers: sharedClientDefaults.headers,
                    })
                    .withInterceptor({
                        async responseError(error) {
                            let json = await error.json();

                            throw json;
                        },
                    });
            });

            await this.httpClient.post('users/check');
        });

        createAppContextProvider({
            httpClient,
            router,
            currentUser,
            errorService,
            dialogService,
        });
    }

    configureRouter(config: RouterConfiguration, router: Router) {
        this.router = router;
        config.options.pushState = true;
        this.openIdConnect.configure(config);
        config.title = 'Pliance';
        config.map([
            {
                route: ['Dashboard', '', 'home', 'index', 'private'],
                name: 'home',
                moduleId: PLATFORM.moduleName('./home/home'),
                nav: true,
                title: 'Dashboard',
                settings: {
                    icon: 'columns-gap',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'Profile',
                name: 'profile',
                href: '/Profile',
                moduleId: PLATFORM.moduleName('./users/profile/profile'),
                nav: false,
                title: 'Chart',
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'Unauthorized',
                name: 'unauthorized',
                href: '/unauthorized',
                moduleId: PLATFORM.moduleName('./unauthorized/unauthorized'),
                nav: false,
                title: 'Unauthorized',
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            //{ route: 'Notifications', name: 'notifications', href: "/Notifications", moduleId: PLATFORM.moduleName('./notifications/notifications'), nav: false, title: 'Notifications', settings: { icon: 'fas fa-bell', roles: [OpenIdConnectRoles.Authenticated], }, activationStrategy: activationStrategy.invokeLifecycle, },
            {
                route: 'Monitoring',
                name: 'monitoring',
                href: '/Monitoring',
                moduleId: PLATFORM.moduleName('./monitoring/monitoring'),
                nav: false,
                title: 'Monitoring',
                settings: {
                    icon: 'fas fa-eye',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            {
                route: 'customers',
                name: 'customers',
                moduleId: PLATFORM.moduleName('./customers/customers'),
                nav: true,
                title: 'Customers',
                settings: {
                    icon: 'people',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            // DEBUG BEGIN
            // { route: 'transactions', name: 'transactions', moduleId: PLATFORM.moduleName('./transactions/transactions'), nav: true, title: 'Transactions', settings: { icon: 'arrow-left-right', roles: [OpenIdConnectRoles.Authenticated], }, activationStrategy: activationStrategy.invokeLifecycle, },
            // DEBUG END
            {
                route: 'Transaction/:id',
                name: 'transaction-details',
                href: '/Transaction',
                moduleId: PLATFORM.moduleName('./transactions/details/transaction-details'),
                nav: false,
                title: 'Transaction',
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            {
                route: 'alerts',
                name: 'alerts',
                moduleId: PLATFORM.moduleName('./alerts/alerts'),
                nav: true,
                title: 'Alerts',
                settings: {
                    icon: 'exclamation-triangle',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'cases',
                name: 'cases',
                moduleId: PLATFORM.moduleName('./cases/cases'),
                nav: true,
                title: 'Cases',
                settings: {
                    icon: 'folder2-open',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            {
                route: 'report-filing',
                name: 'report-filing',
                moduleId: PLATFORM.moduleName('./report-filing/report-filing'),
                nav: true,
                title: 'Reports',
                settings: {
                    icon: 'folder2-open',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            {
                route: 'Report',
                name: 'report',
                href: '/Report',
                moduleId: PLATFORM.moduleName('./reports/list/report-list'),
                nav: false,
                title: 'Report',
                settings: {
                    icon: 'fas fa-paper-plane',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'report/:id',
                name: 'report-details',
                href: '/Report',
                moduleId: PLATFORM.moduleName('./reports/details/report-details'),
                nav: false,
                title: 'Report',
                settings: {
                    icon: 'fas fa-paper-plane',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            {
                route: 'companies',
                name: 'companies',
                href: '/Companies',
                moduleId: PLATFORM.moduleName('./companies/list/company-list'),
                nav: false,
                title: 'Companies',
                settings: {
                    icon: 'fas fa-building',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'companies/:id',
                name: 'company',
                href: '/Company',
                moduleId: PLATFORM.moduleName('./companies/details/company-details'),
                nav: false,
                title: 'Company',
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            {
                route: 'persons',
                name: 'persons',
                href: '/Persons',
                moduleId: PLATFORM.moduleName('./persons/details/person-details'),
                nav: false,
                title: 'Persons',
                settings: {
                    icon: 'fas fa-user',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'persons/:id',
                name: 'person',
                href: '/Person',
                moduleId: PLATFORM.moduleName('./persons/list/person-list'),
                nav: false,
                title: 'Person',
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            {
                route: 'rules',
                name: 'rules-list',
                moduleId: PLATFORM.moduleName('./rules/rules'),
                nav: true,
                title: 'Rules',
                settings: {
                    icon: 'wrench',
                    roles: [OpenIdConnectRoles.Authenticated],
                },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
        ]);

        config.addPreActivateStep(
            new PostStep(() => {
                this.showLoader = true;
            }),
        );

        config.addPostRenderStep(
            new PostStep(() => {
                this.showLoader = false;
            }),
        );
    }

    async attached() {
        // this.loadNotifications();
    }

    async loadNotifications() {
        //         await this.notificationsService.feed();
        //
        //         const connection = new HubConnectionBuilder()
        //             .withUrl(`${config2.appHost}/api/hubs/notificationsHub`, <IHttpConnectionOptions>{
        //                 accessTokenFactory: () => {
        //                     return this.user.access_token;
        //                 }
        //             })
        //             .withAutomaticReconnect()
        //             .build();
        //
        //         connection.on("messageReceived", (username: string, payload: string) => {
        //             this.notificationsService.push(<Notification>JSON.parse(payload));
        //         });
        //
        //         connection.start().catch((err) => console.dir(err));
    }

    async logout() {
        await this.openIdConnect.userManager.removeUser();

        const logoutReturnUri = encodeURIComponent(`${config.appHost}/signout-oidc`);
        const clientId = encodeURIComponent(config.client_id);
        const url = `${config.authSite}/logout?client_id=${clientId}&logout_uri=${logoutReturnUri}`;

        window.location.replace(url);
        // Doesnt work with cognito - not follwing the the specs around logout
        // await this.openIdConnect.logout();
    }
}

class PostStep implements PipelineStep {
    loader: any;

    constructor(loader) {
        this.loader = loader;
    }

    run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
        //this.loader();
        return next();
    }
}
